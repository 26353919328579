import { IPaymentStruct } from '~/types/payment';

export function useCountry() {
  const { getCountries } = useCountries();

  const countries = computed(() =>
    getCountries.value
      ?.map((country: any) => {
        country.name = country.name || '';
        return country;
      })
      ?.sort((a: any, b: any) => a.position - b.position)
  );

  return { countries };
}
