export function useSalutation() {
  const { getSalutations } = useSalutations();

  const salutations = computed(() =>
    getSalutations.value?.map((salutation: any) => {
      salutation.name = salutation.displayName || '';
      return salutation;
    })
  );

  return { salutations };
}
